@import url("https://fonts.googleapis.com/css?family=Lato:300|Roboto:300&display=swap");

.navLink {
  border: none;
  outline: none;
  background-color: transparent;
  font-family: "Roboto";
  font-size: 12pt;
  margin: 0px 50px 0px 50px;
  cursor: pointer;
  white-space: nowrap;
  color: white;
}

.navLink-Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 2vw;
  padding-top: 5px;
}

.header-Container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: #222;
}

.mitchHartigan-Header-Text {
  font-family: "Lato";
  font-size: 18pt;
  margin-top: 20px;
  padding-left: 2vw;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  color: white;
}

.greeting-Hero-Text {
  font-family: "Lato";
  font-size: 50pt;
  text-align: center;
  margin-bottom: 0vh;
  color: white;
  text-shadow: 3px 3px 3px #3d3d3d;
}

.greeting-Sub-Text {
  font-family: "Roboto";
  font-size: 18pt;
  text-align: center;
  font-weight: 300;
  margin-top: 3vh;
  color: white;
  text-shadow: 2px 2px 3px #404040;
}

.greeting-Container {
  width: 100%;
}

.page-Div {
  padding-top: 5vh;
}

.checkMeOut-Container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.checkMeOut-Text {
  font-family: "Roboto";
  font-size: 14pt;
  font-weight: 300;
  margin-bottom: 0px;
  color: white;
  text-shadow: 1px 1px 1.5px #3d3d3d;
}

.technologies-Header-Text {
  font-family: "Lato";
  font-weight: 300;
  font-size: 25pt;
  text-align: center;
  color: white;
}

.technologies-Text-Container {
  width: 100%;
}

.technologies-Title-Text {
  font-family: "Lato";
  font-size: 14pt;
  font-weight: 300;
  margin-bottom: 10px;
  color: white;
}

.technologies-Container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.services-Container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 50%;
}

.services-Container-Parent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.technology-Img-Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4vh 4vh 4vh 4vh;
  max-width: 100px;
}

.technology-Img {
  height: auto;
  width: 100%;
}

.technology-Text {
  font-family: "Roboto";
  font-size: 12pt;
  text-align: center;
  width: 100%;
  color: white;
}

.black-Background {
  background-color: #141414;
}

.pageTitle-Header-Text {
  font-family: "Lato";
  font-size: 30pt;
  text-align: center;
  padding-top: 3vh;
  color: white;
}

.contact-Header-Text {
  font-family: "Lato";
  font-size: 30pt;
  text-align: center;
  margin-top: -2vh;
  padding-bottom: 2vh;
  color: white;
}

.experience-Header-Text {
  font-family: "Lato";
  font-size: 30pt;
  text-align: center;
  color: white;
}

.experience-Sub-Text {
  font-family: "Roboto";
  font-size: 18pt;
  text-align: center;
  font-weight: 300;
  color: white;
}

.contact-Sub-Text {
  font-family: "Roboto";
  font-size: 18pt;
  text-align: center;
  font-weight: 300;
  margin-top: 2vh;
  margin-bottom: 0vh;
  padding-bottom: 3vh;
  color: white;
}

.contact-Link {
  color: white;
}

.experience-Link {
  color: white;
  text-shadow: 2px 2px 2px #3d3d3d;
  cursor: pointer;
}

.display-Button-Live {
  padding: 10px 20px 10px 20px;
  margin: 20px 20px 0px 0px;
  background-color: #141414;
  font-family: "Roboto";
  outline: none;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.display-Button-Github {
  padding: 10px 38px 10px 38px;
  margin: 20px 0px 0px 20px;
  background-color: transparent;
  border: 1px solid #ebebeb;
  font-family: "Roboto";
  font-weight: 400;
  outline: none;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.display-Img {
  width: 100%;
  height: auto;
}

.display-Img-Container {
  max-width: 500px;
}

.project-Title-SubText {
  font-family: "Lato";
  font-size: 14pt;
  font-weight: 300;
  margin-bottom: 10px;
  color: white;
}

.title-Text {
  font-family: "Lato";
  font-size: 14pt;
  font-weight: 300;
}

.project-Display-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.display-Buttons-Container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.bulletPoint-Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-Container-Parent {
  margin-bottom: 10vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-Description-Container {
  margin-left: 3vw;
  margin-top: 0vh;
}

.project-Content-Container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.description-Text-Container {
  max-width: 25vw;
  min-width: 300px;
}

.project-Description-Text {
  font-family: "Roboto";
  font-size: 12pt;
  font-weight: 300;
  margin-top: 0px;
  line-height: 1.35;
  color: #ebebeb;
}

.project-Title-Text {
  font-family: "Lato";
  font-size: 20pt;
  font-weight: 300;
  text-align: left;
  margin-bottom: 3vh;
  color: white;
}

.my-Experience-Div {
  padding-top: 0vh;
  margin-bottom: 15vh;
}

.testimonial-Text {
  max-width: 70vw;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 18pt;
  margin-bottom: 0vh;
  margin-top: 10vh;
  color: white;
}

.testimonial-Sub-Text {
  max-width: 70vw;
  font-family: "Lato";
  font-weight: 300;
  font-size: 18pt;
  color: white;
}

.testimonial-Text-Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

ul {
  padding-left: 20px;
  list-style-type: circle;
  margin-top: 0px;
}

h4 {
  margin-bottom: 0px;
}

@media screen and (max-width: 908px) {
  .project-Description-Container {
    width: 100%;
  }
  .description-Text-Container {
    max-width: none;
  }
  .project-Display-Container-Parent {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .project-Display-Container {
    align-items: center;
  }

  .display-Buttons-Container {
    justify-content: center;
  }

  .project-Title-Text {
    text-align: center;
  }

  .technologies-Title-Text {
    padding-left: 3vw;
  }

  .display-Img-Container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .bulletPoint-Container {
    justify-content: flex-start;
    padding: 0vh 3vh 0vh 3vh;
  }

  .bulletedList-Container {
    padding-right: 10vw;
  }
}

@media screen and (max-width: 700px) {
  .navLink-Parent {
    display: none;
    padding: none;
  }

  .checkMeOut-Container {
    padding-top: 35vh;
  }

  .header-Container {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 450px) {
  .greeting-Container {
    width: 100%;
    padding-top: 20vh;
    z-index: 0;
  }

  .greeting-Hero-Text {
    font-size: 35pt;
  }

  .greeting-Sub-Text {
    font-size: 14pt;
  }

  .checkMeOut-Text {
    font-size: 12pt;
  }

  .testimonial-Text {
    font-size: 14pt;
  }
  .testimonial-Sub-Text {
    font-size: 14pt;
  }

  .experience-Sub-Text {
    font-size: 14pt;
  }

  .contact-Sub-Text {
    font-size: 14pt;
  }
}

@media screen and (max-width: 330px) {
  .description-Text-Container {
    max-width: 300px;
  }

  .greeting-Container {
    width: 100%;
    padding-top: 10vh;
  }

  .header-Container {
    max-width: 320px;
  }
  .project-Container {
    max-width: 320px;
  }
  .project-Title-Text {
    max-width: 320px;
  }
  .project-Content-Container {
    max-width: 320px;
  }
  .project-Display-Container-Parent {
    max-width: 320px;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #222;
}
